// Default translations located in
// node_modules/@brainstud/react-qbl/core/translations.js

// You can overwrite any of the values
// For dynamic data use double curly braces {{param}}

// HTML is activated for properties that end with HTML

export default {

}