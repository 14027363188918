import React from 'react'
import { TourSteps, TourStep } from '@brainstud/react-widget-tour'
import { Tour } from '@brainstud/react-qbl/features/tour'

function QuestionTour() {
  return (
    <Tour>
      <TourSteps>
        <TourStep
          maskStyle={{ borderRadius: '50%' }}
          el={() => {
            return document.querySelectorAll('[class^="BackButton_button"]')[0]
          }}
        >
          <p className="text-em">
            Wij adviseren om een score van
          </p>
        </TourStep>

        <TourStep
          tooltip={{ alignment: 'center' }}
          el={() => {
            return document.querySelectorAll('[class^="CourseQuestionHeader_progress"]')[0]
          }}
        >
          <p className="text-em">
            Wij adviseren om een score van
          </p>
        </TourStep>

        <TourStep
          tooltip={{ alignment: 'right' }}
          el={() => {
            return document.querySelectorAll('[class^="CourseQuestionHeader_timer"]')[0]
          }}
        >
          <p className="text-em">
            Wij adviseren om een score van
          </p>
        </TourStep>

        <TourStep
          el={() => {
            return document.querySelectorAll('[class^="Container_base"] > div')[0]
          }}
        >
          <p className="text-em">
            Wij adviseren om een score van
          </p>
        </TourStep>

        <TourStep
          tooltip={{ position: 'right', alignment: 'bottom' }}
          el={() => {
            return document.querySelectorAll('[class^="Button_base"]')[0]
          }}
        >
          <p className="text-em">
            Wij adviseren om een score van
          </p>
        </TourStep>

        <TourStep
          tooltip={{ position: 'top', alignment: 'right' }}
          el={() => {
            return document.querySelectorAll('[class^="Button_base"]')[1]
          }}
        >
          <p className="text-em">
            Heb je het gewenste antwoord geselecteerd? Klik dan op deze knop om je antwoord te controleren
          </p>
        </TourStep>
      </TourSteps>
    </Tour>
  )
}

export default QuestionTour