import fileMarcomA from "./assets/Standaardbijlage Marcom A 2018-2019.pdf";
import fileMarcomB from "./assets/Standaardbijlage Marcom B 2018-2019.pdf";

export default {
  files: [
    {
      name: "Standaardbijlage Marcom A 2018-2019.pdf",
      location: fileMarcomA,
      courseId: "01312330-76a4-4107-a606-3b747047e22d",
    },
    {
      name: "Standaardbijlage Marcom B 2018-2019.pdf",
      location: fileMarcomB,
      courseId: "30602490-ea4d-49ec-9d43-661f6e664329",
    },
  ]
}
