import React from 'react'
import { TourSteps, TourStep } from '@brainstud/react-widget-tour'
import { Tour } from '@brainstud/react-qbl/features/tour'

function DashboardTour() {
  return (
    <Tour>
      <TourSteps>
        <TourStep
          el={() => {
            return document.querySelectorAll('[class^="CourseProgress_progress"]')[2]
          }}
        >
          <p className="text-em">
            Wij adviseren om een score van minimaal 80% te halen op elk onderdeel voor je het examen begint!
          </p>
        </TourStep>

        <TourStep
          el={() => {
            return document.querySelectorAll('[class^="Card_base"]')[0].querySelectorAll('[class^="Button_base"]')[0]
          }}
        >
          <p className="text-em">
            Oefen alle thema’s tenminste 1 keer om je aan te kunnen melden voor het examen op locatie.
          </p>
        </TourStep>

        <TourStep
          el={() => {
            return document.querySelectorAll('[class^="Card_base"]')[0].querySelectorAll('[class^="Button_base"]')[1]
          }}
        >
          <p className="text-em">
            Oefen alle thema’s tenminste 1 keer om je aan te kunnen melden voor het examen op locatie.
          </p>
        </TourStep>

        <TourStep
          el={() => {
            return document.querySelectorAll('[class^="Sidebar_courses"]')[0]
          }}
        >
          <p className="text-em">
            Wij adviseren om een score van minimaal 80% te halen op elk onderdeel voor je het examen begint!
          </p>
        </TourStep>

        <TourStep
          el={() => {
            return document.querySelectorAll('[class^="Sidebar_bottom"]')[0]
          }}
        >
          <p className="text-em">
            Wij adviseren om een score van minimaal 80% te halen op elk onderdeel voor je het examen begint!
          </p>
        </TourStep>
      </TourSteps>
    </Tour>
  )
}

export default DashboardTour