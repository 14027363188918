import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import {App, Preloader} from '@brainstud/react-qbl'
import {Coach, Course, Dashboard, Home, Question, Student} from '@brainstud/react-qbl/hooks'
import {store, Theme} from '@brainstud/react-qbl/themes/default'

import {DashboardTour, QuestionTour} from './tours'
import translations from './translations'
import config from './config';

const initialState = {}


const Init = () => (
  <App store={store(initialState)} config={config}>
    <Preloader
      components={{}}
    >
      <Theme
        translations={translations}
      >
        <Home previewCard={<></>}>
          <h2>Welkom bij</h2>
          <h1>Friese Poort Maritiem</h1>
          <p>Selecteer hieronder de cursus die je wilt volgen</p>
        </Home>

        <Coach>
          <Dashboard />
          <Course />
        </Coach>

        <Student>
          <Dashboard
            tour={<DashboardTour />}
          />

          <Question
            tour={<QuestionTour />}
          />
        </Student>
      </Theme>
    </Preloader>
  </App>
)

ReactDOM.render(<Init />, document.getElementById('root'))
